import React, { useState } from "react";
import { MessageContact } from "../../../utils/api/contact/contact.type";
import moment from "moment";
import { FiFolderMinus } from "react-icons/fi";
import Collapse from "react-bootstrap/Collapse";
import { useAddOrUpdateContactMutation, useGetContactListQuery, useUpdateContactMutation } from "../../../utils/api/contact/contact.api";
import DetailsMessageModal from "./modals/DetailsMessageModal";
import { AlertInfo } from "../../common/Alert";
import { getAvatar, getName } from "../../../utils/Utils";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";

export const getType = (type: "mise_en_relation" | "plainte") => {
  let text = "Contact";

  if (type === "plainte") {
    text = "Signalement";
  }

  return text;
};

export const getStatut = (type: MessageContact["status"]) => {
  let text = "Nouveau";

  if (type === "in_progress") {
    text = "En cours";
  }

  if (type === "traited") {
    text = "Terminé";
  }

  return text;
};

function ContactItem() {
  // { q }: { q: string }
  const { data = { results: [], count: 0 }, isLoading } =
    useGetContactListQuery({});

  return <MessageContactList data={data?.results} />;
}

export default ContactItem;

function MessageContactList({ data }: { data: MessageContact[] }) {
  return (
    <div>
      {data.map((item) => (
        <MessageContactItem item={item} key={item.slug} />
      ))}
      {data.length === 0 && <AlertInfo message="Pas de message" />}
    </div>
  );
}

function MessageContactItem({ item }: { item: MessageContact }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openCollapse, setOpenCollapse] = useState({});
  const toggleCollapse = (itemId) => {
    setOpenCollapse((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  const onEdit = UseEditStatutMessage(item);
  return (
    <>
      <div className="mb-3">
        <div className="px-2 py-3 admin-message-contact-card mb-2 flex-m gap-1">
          <div className="row w-100 align-items-center">
            <div className="col-md-4">
              <div className="d-flex align-items-start gap-2">
                <div className="message-item-without-input-container">
                  <div
                    className="admin-message-contact-card-description d-flex btn p-0"
                    style={{
                      textOverflow: "ellipsis",
                    }}
                    onClick={handleShow}
                    role="button"
                  >
                    {item.subject?.slice(0, 50) + "..." ??
                      item.message?.slice(0, 50) + "..."}
                  </div>
                  <p className="admin-message-contact-card-frequence fz-14 mb-0">
                    {moment(item.created_at).format("DD/MM/YYYY [à] HH:mm")}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="flex-c col-md-2"
              //   onClick={() => showModal(`messageModal${item?.slug}`)}
            >
              <p
                className={`text-date-message mb-0 badge ${item?.contact_type}`}
              >
                {getType(item?.contact_type)}
              </p>
            </div>
            <div className="flex-c col-md-2">
              <p
                className={`text-date-message mb-0 badge ${item?.status}`}
                onClick={() => onEdit()}
              >
                {getStatut(item?.status)}
              </p>
            </div>
            <div className="flex-c col-md-2">
              <div className="content-label-img-profil-mmessage">
                <div className="content-img-profil-mmessage">
                  <img
                    src={getAvatar(item?.user?.avatar)}
                    alt={item?.user?.nom + item?.id}
                    className="img-profil-mmessage"
                  />
                </div>
                <p className="text-name-profil-message mb-0">
                  {getName(item?.user)}
                </p>
              </div>
            </div>
            <div className="col-md-2 flex-r">
              <button
                className="no-style-btn accordion-button"
                type="button"
                onClick={() => toggleCollapse(item.slug)}
                aria-expanded={openCollapse[item.slug]}
                aria-controls={`collapse-${item.slug}`}
              >
                <FiFolderMinus />
              </button>
            </div>
          </div>
        </div>
        <Collapse in={openCollapse[item.slug]}>
          <div className="card card-body admin-content-message mb-1">
            {item.message}
          </div>
        </Collapse>
      </div>
      <DetailsMessageModal item={item} show={show} handleClose={handleClose} />
    </>
  );
}

export function UseEditStatutMessage(item: MessageContact) {
    const [sendData] = useUpdateContactMutation();
    const onEdit = async () => {
      await Swal.fire({
        title: `Statut du message`,
        input: "select",
        inputValue: item?.status,
        inputOptions: {
          new: "Nouveau",
          in_progress: "En cours",
          traited: "Terminer",
        },
        inputPlaceholder: "Selectionner un statut",
        showCancelButton: true,
        confirmButtonText: "Modifier",
        cancelButtonText: "Annuler",
        reverseButtons: true,
        confirmButtonColor: Color.themeColor,
        showLoaderOnConfirm: true,
  
        preConfirm: (status) => {
          console.log("statut", status);
          if (!status) {
            return Swal.showValidationMessage(`Veuillez sélectionner un statut`);
          }
          return sendData({
            slug: item?.slug,
            status: status,
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result: any) => {
        if (result?.isConfirmed) {
          if (result?.value && !result?.value?.error) {
            Swal.fire({
              icon: "success",
              title: `Le statut du message a été modifié avec succès!`,
              iconColor: Color.success,
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            let err = result?.value?.error;
            Swal.fire({
              icon: "error",
              title: err?.data?.message
                ? err?.data?.message
                : `Une erreur de statut ${err?.status} est survenue!`,
              showConfirmButton: false,
              timer: 5000,
            });
          }
        }
      });
    };
    return onEdit;
  }